import React, {useEffect, useState} from "react";
import {datadogRum} from "@datadog/browser-rum";
import { Button, Grid } from "mesh-component-library";
import {AutoC, ClocktimeF, CreditcardC} from "../../icon-library";
import TrackStatus from "../../components/svg/TrackStatus";
import renderIcon from "../../utilities/render-icon";
import Icon from "../../components/icon";
import ProviderInfo from "../../components/providerinfo";
import TrackerMap from "../../components/tracker-map";
import {OvercostWarning} from "../../components/warning-alerts";
import {
  multilanguageSoftServiceSteps,
  multilanguageTowSteps,
  STATUS_FOR_HIDE_CANCEL,
  updatePredictiveMessagingConstant,
  rentalTwoWayTowSteps,
  passengerDropOffTowSteps,
  reverseTowSteps,
  ETA_NOT_REQUIRED_STATUSES,
  CALCULATED_NO_GPS,
  DATA_DRIVEN_ETA
} from "../Tracker/status-data";
import {GoneOnArrival} from "../../components/gone-on-arrival";
import SlideArrow from "../../components/svg/SlideArrow";
import {
  CANCELED,
  CANCELLED,
  COMPLETED,
  DROP_SIGN_RSV,
  GONE_ON_ARRIVAL_RSV,
  NEW_JOB_RSV,
  PPU,
  RESCUE_CUSTOMER_VERSION,
  RESCUE_WLEOA,
  RESCUE_WLES,
  TOW,
  YES,
  BOOKED,
  DROP_PASSG,
  GPS,
  ASSIGNED,
  PEP,
  AMAZON_CONNNECT_CHAT_USER_TYPE,
  EN_ROUTE
} from "../../app-consts/appConstants";
import {INTAKE_COMPLETED} from "../../jacada/selectors";
import {TRANSLATION_CONSTANTS} from '../../app-consts/translations';
import {getPredictiveETA, getRescueTrackerStatus, getShortenedUrl} from "../../api/api";
import Spinner from "../../components/spinner";
import {useLocale} from "../../contexts/LocaleContext";
import {CancellationModal} from "../../components/cancellationmodal";
import Alert from "../../components/alert";
import ImageLink from "../../components/image-link";
import ShareButton from '../../components/sharebutton';
import ModalDialog from '../../components/modal-dialog';
import useInterval from "../../hooks/useInterval";
import {useDispatch, useSelector} from "react-redux";
import {getAmazonConnectSelector, getCommonBenefits, getPartnerDetails, getRequestInfo, getServiceInfo, getServiceRequestPayload} from "../../selector";
import {
  allowValidUrlCharacters,
  allowNumbers
} from "../../utils/regexUtil";
import Lyft from "../../components/lyft";
import { sendJacadaRequest } from '../../api';
import { colorTranslatedText, serviceTranslatedText } from "../../utils/translatedTextUtils";
import titleCase from "../../utils/titleCase";
import { clearDuplicateRescue, refreshReducersToIntial, updatePartnerDetailsDisplayCode, updatePartnerDetailsPartnerCode, updatePartnerDetailsRoute } from "../../action";
import { disablementLocationTextByService,
  getUpdatedClock,
  getUserTypeByLinkType } from '../../utils/rescueTrackerUtil';
import { generatePathWithSearchParams } from "../../utilities/RedirectUtils";
import { useHistory } from "react-router";
import RentalExchange from "../../components/rental-exchange";
import { PeopleFlagIcon, PersonIcon, GiftIcon } from "mesh-icon-library";
import { Icon as MeshIcon, Slab, Type } from "mesh-component-library";
import CreditCardOffer from "../../components/credit-card-offer";
import { formatMinutesToTimeRange, formatTimeForRescueTracker } from "../../utils/timeUtil";
import { getVaChat, injectAmazonConnect } from "../../utils/amazonConnect";
import { isEmpty } from "lodash";

const initialStatusResponseState = {
  addresses: [],
  providers: [],
  services: [],
}

const RescueTracker = (props) => {
  const partnerDetails = useSelector(getPartnerDetails);
  const amazonConnect = useSelector(getAmazonConnectSelector);
  const commonBenefits = useSelector(getCommonBenefits);
  const serviceRequestPayload = useSelector(getServiceRequestPayload);
  const serviceInfo = useSelector(getServiceInfo);
  const submitRequest = useSelector(getRequestInfo)
  const dispatch = useDispatch();
  const history = useHistory();

  const partnerCode = props.match.params.partnerCode;
  const usageId = props.match.params.usageId;
  const linkType = props.match.params.linkType;
  const [statusResponse, setStatusResponse] = useState(initialStatusResponseState)
  const [isLoading, setIsLoading] = useState(true);
  const [errorCount, setErrorCount] = useState(0);
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showCancellationError, setShowCancellationError] = useState(false)
  const [showRescueStatusErrorWithNoPreviousSuccess, setShowRescueStatusErrorWithNoPreviousSuccess] = useState(false)
  const [rescueCancelled, setRescueCancelled] = useState(false)
  const [showRequestSummarySection, setShowRequestSummarySection] = useState(true)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [textHref, setTextHref] = useState("")
  const [callId, setCallId] = useState("")
  const [smsRescueTrackerStatus, setSmsRescueTrackerStatus] = useState("");
  const [isETAMessageBeShown, setETAMessageBeShown] = useState(false);
  const getTranslatedText = useLocale()
  const displayCountdown = showRescueStatusErrorWithNoPreviousSuccess || errorCount > 5
  const timeLeft = useInterval(updateRescueTrackerStatus, 30000, displayCountdown);

  const { displayCode, experience, omniStreamlined = "", route = "" } = partnerDetails;
  const { serviceRequest } = serviceRequestPayload;
  const [ isProviderCancelClicked, setProviderCancelClicked ] = useState(false);
  const [ amazonConnectScriptLoaded, setAmazonConnectScriptLoaded] = useState(false);
  const [predictiveETA, setPredictiveETA] = useState({});

  async function updateRescueTrackerStatus() {
    if(showRescueStatusErrorWithNoPreviousSuccess) setIsLoading(true)
    const result = await getRescueTrackerStatus(partnerCode, usageId, "usageid")
    setSmsRescueTrackerStatus(result?.providers[0]?.currentCheckpointStatus);
    if (result) {
      if (window.location.pathname.split('/')[2] !== 'f' 
      && !!omniStreamlined
      && smsRescueTrackerStatus === COMPLETED
      && !!submitRequest?.success) {
          history.push(generatePathWithSearchParams(`/${route}/${partnerCode}/feedback`));
      }
      setErrorCount(0)
      if(showRescueStatusErrorWithNoPreviousSuccess){
        setShowRescueStatusErrorWithNoPreviousSuccess(false)
      }
      if (smsRescueTrackerStatus?.toUpperCase() === CANCELLED ||
        smsRescueTrackerStatus?.toUpperCase() === CANCELED) {
            setProviderCancelClicked(true);
      }
      setStatusResponse(result)
      if (
        result?.rescueStatus.toUpperCase() === CANCELLED ||
        result?.rescueStatus.toUpperCase() === CANCELED
        ) {
          setRescueCancelled(true)
      }
      if (callId === "") {
        setCallId(result.initialCallId)
      }
    } else {
      if(!statusResponse.initialCallId){
        datadogRum.addError("RT standalone: initial error", {partnerCode, usageId})
        setShowRescueStatusErrorWithNoPreviousSuccess(true)
      } else {
        errorCount === 5 && datadogRum.addError("RT standalone: six consecutive errors", {partnerCode, usageId})
        setErrorCount(errorCount+1)
      }
    }
    setIsLoading(false)
    return result;
  }

  useEffect(() => {
    document.title = "Tracker | Roadside Assistance";
    window.scrollTo(1, 1);
    updateRescueTrackerStatus().then(rsvResponse => callPredictiveETA(rsvResponse));
  }, []);

  const callPredictiveETA = async rsvResponse => {
    const status = rsvResponse?.providers[0]?.currentCheckpointStatus;
    if (status === NEW_JOB_RSV && rsvResponse?.rescueStatus?.toUpperCase() !== CANCELLED && rsvResponse?.rescueStatus?.toUpperCase() !== CANCELED) {
      const currentLocation = rsvResponse?.addresses?.find(address => address.addressType.toUpperCase() === "DISABLEMENT PICK UP");
      const predictiveETAResult = await getPredictiveETA({currentLocation, isTow: rsvResponse.towService, appId: "RT-SMS", sessionId: window.location.href.split("/")?.[6], partnerCode});
      if (predictiveETAResult) {
        const {petaFrom, petaTo} = formatMinutesToTimeRange(rsvResponse.towService, predictiveETAResult, new Date(rsvResponse?.dispatchTime));
        setPredictiveETA({petaFrom, petaTo});
      }
    }
  }

  useEffect(() => {
    setETAMessageBeShown(false);
    setTimeout(() => {
      setETAMessageBeShown(true);
    }, 30000);
  }, [smsRescueTrackerStatus]);

  useEffect(() => {
    if (RESCUE_WLES.includes(linkType) && rescueCancelled) {
      let userType = getUserTypeByLinkType(linkType);
      let page;
      if (
        experience &&
        experience.passThruEnabled &&
        experience.serviceSelectionEnabled
      ) {
        page = "lookup";
      } else {
        page =
          userType === PPU || window.location.href.indexOf("verizon") > -1
            ? ""
            : "memberlookup";
      }
      localStorage.clear();
      localStorage.setItem("rescueCancel", "YES");
      localStorage.setItem("isPpu", serviceRequest.ppuFlag === YES ? "true" : "false");
      dispatch(refreshReducersToIntial());
      history.push(
        generatePathWithSearchParams(`/${userType}/${partnerCode}/${page}`)
      );
    } else if (RESCUE_WLES.includes(linkType) && showCancellationError) {
      let userType = getUserTypeByLinkType(linkType);
      dispatch(refreshReducersToIntial());
      history.push(
        generatePathWithSearchParams(`/${userType}/${partnerCode}/sitedown`)
      );
    }
  }, [rescueCancelled, showCancellationError, linkType]);

  useEffect(() => {
    if(linkType === RESCUE_WLEOA && callId){
      sendJacadaRequest({
        'externalSystemId': localStorage.getItem('conversationId'),
        'events': {
          'name': 'call_id',
          'value': callId
        }
      });
    }
  }, [callId, linkType])

  useEffect(() => {
      if (callId !== "" && window.location.pathname.split('/')[2] !== 'f') {
        injectAmazonConnect(amazonConnect, ({loaded = false}) => {
          if(loaded) {
            setAmazonConnectScriptLoaded(true);
          }
        })
      }
  }, [callId, window.location.pathname]);

  useEffect(() => {
    if(amazonConnectScriptLoaded) {
      getVaChat().set({
        attributes: {
          userType: AMAZON_CONNNECT_CHAT_USER_TYPE,
          usageId: usageId,
          va_botname: amazonConnect?.botName,
          firstName: statusResponse?.customer?.firstName,
          lastName: statusResponse?.customer?.lastName,
          appointmentNumber: `${callId}`
        },
      });
      getVaChat().initializeChat();
    }
  }, [amazonConnectScriptLoaded]);

  const formatAddress = address => address ? `${titleCase(`${address.line1}, ${address.city}`)}, ${address.state} ${address.zipCode}, ${address.country}` : ''

  const buildTextHref = async () => {
    setOpenShareModal(false)
    const deviceAgent = navigator.userAgent.toLowerCase();
    const isIOS =  /(iphone|ipod|ipad)/.test(deviceAgent);
    const paramOperator = (isIOS) ? "&" : "?";
    let preFix = `sms:${paramOperator}body=Track My Rescue Now `;

    const friendsAndFamilyShareUrl = await createShareShortUrl(usageId, partnerCode)
    return preFix + friendsAndFamilyShareUrl;
  };

  const shareRescueOnClick = async () => {
    const href = await buildTextHref()
    setTextHref(href)
    datadogRum.addAction(`SMS RT share with family and friends with url: ${href}`)
    setOpenShareModal(true)
  }

  const renderRequestSummarySection = () => {

    const currentAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "DISABLEMENT PICK UP")
    const destinationAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "DISABLEMENT DROP OFF")
    const exchangePickUpAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "EXCHANGE PICK UP")
    const exchangeDropOffAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "EXCHANGE DROP OFF")
    const passengerDropOffAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "CUSTOMER DROP OFF")
    const cost = parseFloat(statusResponse?.cost)?.toFixed(2);
    const { vehicle } = statusResponse;

    let coveredMilesLimit;
    let costPerAdditionalMile;
    let maskedNumber;
    if(RESCUE_WLES.includes(linkType)){
      if (serviceInfo.value === TOW) {
        let services = commonBenefits?.benefits ? commonBenefits.services : experience?.serviceCost?.serviceCosts
        let towServiceInfo =  services?.find(service => service.type === 'Tow' || service.serviceType === 'Tow')
        coveredMilesLimit = towServiceInfo?.towIncludedQty || parseInt(towServiceInfo?.towIncludedDistanceQty);
        costPerAdditionalMile = towServiceInfo?.towExtraUnitAmt || towServiceInfo?.costPerMile;
      }
      maskedNumber = serviceRequest.CCardInfo?.maskedCardNumber;
    }
    return (
      <div className="l-confirmation-container">
        <div className="l-container-location request-section">
          <div
            role="link"
            tabIndex="0"
            className="request-section-header"
            onClick={() => setShowRequestSummarySection(!showRequestSummarySection)}
            onKeyDown={() => setShowRequestSummarySection(!showRequestSummarySection)}
          >
            <div>
              <h4 id={INTAKE_COMPLETED} className="title">{getTranslatedText(TRANSLATION_CONSTANTS.REQUEST_SUMMARY)}</h4>
              <h5 id="reference-number" className="c-current-location__tow">
                {getTranslatedText(TRANSLATION_CONSTANTS.REFERENCE_NUMBER)}: {statusResponse.initialCallId}{" "}
              </h5>
            </div>
            <SlideArrow
              direction="up"
              color="blue"
              size="large"
              rotate={`${!showRequestSummarySection ? "" : "rotate"}`}
            />
          </div>
          <div
            data-testid="requestSummaryDetails"
            hidden={!showRequestSummarySection}
          >
            <div className={`${statusResponse.towService ? (exchangePickUpAddress && exchangeDropOffAddress ? 'c-current-location--rt-tow-exchange ' : 'c-current-location--rt-tow ') : ''} c-current-location c-rt-tow-margin`}>
              <React.Fragment>
                <Icon className="c-current-location__icon"
                  icon={renderIcon(statusResponse.services[0]?.name)}
                  color="brand"
                  size={1.5} />
                <h4 className="c-current-location__tow">
                  {determineRescueTitlesAndStatusMessaging(false)}
                </h4>
              </React.Fragment>
              {exchangePickUpAddress && exchangeDropOffAddress ?
                <React.Fragment>
                  <h6 className="c-exchange-location__title">{getTranslatedText(TRANSLATION_CONSTANTS.NEW_VEHICLE_PICKUP_LOCATION)}</h6>
                  <p data-testid="exchangePickUpAddress" className="c-exchange-location__address" data-dd-privacy="mask">{formatAddress(exchangePickUpAddress)}</p>
                  <h6 className="c-exchange-location__dropoff-title" >{getTranslatedText(TRANSLATION_CONSTANTS.NEW_VEHICLE_DROP_0FF_LOCATION)}</h6>
                  <p data-testid="exchangeDropOffAddress" className={`${currentAddress ? 'c-exchange-location__dropoff-address--RentalTwoWay' : ''} c-exchange-location__dropoff-address`} data-dd-privacy="mask">{formatAddress(exchangeDropOffAddress)}</p>
                  {currentAddress && destinationAddress &&
                    <React.Fragment>
                      <h6 className="c-rt-current-location__title">{getTranslatedText(TRANSLATION_CONSTANTS.DISABLED_VEHICLE_PICKUP_LOCATION)}</h6>
                      <p data-testid="currentAddress" className="c-rt-current-location__address" data-dd-privacy="mask">{formatAddress(currentAddress)}</p>
                      <h6 className="c-rt-current-location__dropoff-title">{getTranslatedText(TRANSLATION_CONSTANTS.DISABLED_VEHICLE_DROP_0FF_LOCATION)}</h6>
                      <p data-testid="destinationAddress" className="c-rt-current-location__dropoff-shop-name" data-dd-privacy="mask">{formatAddress(destinationAddress)}</p>
                    </React.Fragment>
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  <React.Fragment>
                    <h6 className="c-current-location__title">{getTranslatedText(disablementLocationTextByService(statusResponse.services[0]?.name))}</h6>
                    <p data-testid="currentAddress" className="c-current-location__address" data-dd-privacy="mask">{formatAddress(currentAddress)}</p>
                  </React.Fragment>
                  {statusResponse.towService &&
                    <React.Fragment>
                      <h6 className="c-current-location__dropoff-title"> {getTranslatedText(TRANSLATION_CONSTANTS.DESTINATION_LOCATION)}</h6>
                      <p data-testid="destinationAddress" className="c-current-location__dropoff-shop-name" data-dd-privacy="mask">{formatAddress(destinationAddress)}</p>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </div>
            {passengerDropOffAddress &&
              <div className="c-contact-payment__payment-info c-rt-margin">
                <div className="u-flex u-flex--middle u-flex--start">
                  <div className="icon-person u-hr-2">
                    <MeshIcon icon={PeopleFlagIcon} color="#FFF" size={1.5} />
                  </div>
                  <h4 className="c-contact-payment__title">{getTranslatedText(TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_LOCATION)}</h4>
                </div>
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p data-testid="passengerDropOffAddress" className="c-contact-payment__benefits-text mbottom" data-dd-privacy="mask">
                    {formatAddress(passengerDropOffAddress)}
                  </p>
                </div>
              </div>
            }
            <div className="c-container-vehicle c-rt-margin">
              <Icon icon={AutoC} color="brand" size={1.5}/>
              <h4 className="c-container-vehicle__title"> {getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE)}  </h4>
              <h6
                className="c-container-vehicle__values u-text-capitalize">{`${vehicle?.year} ${getTranslatedText(colorTranslatedText(vehicle?.color))} ${vehicle?.make} ${vehicle?.model} `}</h6>
            </div>
            <div className="c-contact-payment__payment-info c-rt-margin">
              <div className="u-flex u-flex--middle u-flex--start">
                <div className="icon-person u-hr-2">
                  <MeshIcon icon={PersonIcon} color="#FFF" size={1.5} />
                </div>
                <h4 className="c-contact-payment__title">{getTranslatedText(TRANSLATION_CONSTANTS.CONTACT)}</h4>
              </div>
              {statusResponse?.customer?.firstName &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__benefits-text mbottom" data-dd-privacy="mask">
                    {titleCase(statusResponse.customer.firstName + ' ' + (statusResponse.customer.lastName ?? ''))}
                  </p>
                </div>
              }
              {statusResponse?.customer?.emailId &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left">
                  <p className="c-contact-payment__benefits-text mbottom" data-dd-privacy="mask">{statusResponse.customer.emailId.toLowerCase()}</p>
                </div>
              }
              {statusResponse?.customer?.contactNumber &&
                <div className="u-flex u-flex--top u-flex--between u-hr-p-left" data-dd-privacy="mask">
                  <p className="c-contact-payment__benefits-text mbottom">{statusResponse.customer.contactNumber}</p>
                </div>
              }
            </div>
            {cost !== "0.00" &&
              <div className="c-container-vehicle c-rt-margin">
                <Icon icon={CreditcardC}
                      color="brand"
                      size={1.5}
                />
                <h4 className="u-flex u-flex--top u-flex--between">{getTranslatedText(TRANSLATION_CONSTANTS.PAYMENT)}</h4>
                <h6 className="c-container-vehicle__values ">${cost}</h6>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  const renderCancelRescueSection = () => (
    <>
      {showCancelModal ? (
        <CancellationModal
          partnerCode={partnerCode}
          usageId={usageId}
          setRescueCancelled={setRescueCancelled}
          setShowCancellationError={setShowCancellationError}
          useAlternateCancellationEndpoint={true}
          clearDuplicateRescue={() => dispatch(clearDuplicateRescue())}
          requestId={statusResponse.initialCallId}
          hideCancelModal={() => setShowCancelModal(false)}
          cancellationSource={RESCUE_WLES.includes(linkType) ? "WLE" : "RT"}
          ppuFlag={serviceRequest.ppuFlag}
          updatePartnerDetailsPartnerCode={() => dispatch(updatePartnerDetailsPartnerCode(partnerCode))}
          updatePartnerDetailsDisplayCode={() => dispatch(updatePartnerDetailsDisplayCode(displayCode))}
          updatePartnerDetailsRoute={() => dispatch(updatePartnerDetailsRoute(partnerDetails?.route))}
          refreshReducersToInitial={() => dispatch(refreshReducersToIntial())}
          history={history}
        />
      ) : null}
      <Grid.Column
        col={{ sm: 10, md: 2, lg: 2, xl: 2 }}
        offset={{ sm: 1, md: 5, lg: 5, xl: 5 }}
      >
        <Button
          hasUpgrade
          isPrimary={false}
          size="lg"
          onClick={() => setShowCancelModal(true)}
          data-testid="cancelRescueButton"
          utils={{
            fullWidth: true,
          }}
        >
          {getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_RESCUE)}
        </Button>
      </Grid.Column>
    </>
  );

  const disablementPickUPAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "DISABLEMENT PICK UP")
  const disablementDropOffAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "DISABLEMENT DROP OFF")
  const exchangePickUpAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "EXCHANGE PICK UP");
  const exchangeDropOffAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "EXCHANGE DROP OFF");
  const passengerDropOffAddress = statusResponse.addresses.find(address => address.addressType.toUpperCase() === "CUSTOMER DROP OFF");

  const checkIfTwoWayTow = () => {
    if ((disablementPickUPAddress && disablementDropOffAddress && exchangePickUpAddress && exchangeDropOffAddress) ||
        (exchangePickUpAddress && exchangeDropOffAddress) ||
        (disablementPickUPAddress && disablementDropOffAddress && passengerDropOffAddress)) {
          return true;
    } else return false;
  }

  const determineRescueTitlesAndStatusMessaging = trackeringService => {
    let returnVal;
    if (statusResponse.towService) {
      if (disablementPickUPAddress && disablementDropOffAddress && exchangePickUpAddress && exchangeDropOffAddress) {
        if (trackeringService) {
          returnVal = rentalTwoWayTowSteps;
        } else returnVal = `${getTranslatedText(TRANSLATION_CONSTANTS.EXCHANGE_VEHICLE)} ${getTranslatedText(TRANSLATION_CONSTANTS.TOW).toLowerCase()}`;
        return returnVal;
      } else if (exchangePickUpAddress && exchangeDropOffAddress) {
        if (trackeringService) {
          returnVal = reverseTowSteps;
        } else returnVal = getTranslatedText(TRANSLATION_CONSTANTS.REPLACEMENT_VEHICLE_DELIVERY);
        return returnVal;
      } else if (disablementPickUPAddress && disablementDropOffAddress && passengerDropOffAddress) {
        if (trackeringService) {
          returnVal = passengerDropOffTowSteps;
        } else returnVal = getTranslatedText(TRANSLATION_CONSTANTS.TOW);
        return returnVal;
      } else {
        if (trackeringService) {
          returnVal = multilanguageTowSteps;
        } else returnVal = getTranslatedText(TRANSLATION_CONSTANTS.TOW);
        return returnVal;
      }
    } else {
      return trackeringService ? multilanguageSoftServiceSteps : getTranslatedText(serviceTranslatedText(statusResponse.services[0]?.name));
    }
  }

  const renderTrackRescueSection = (rescueStatus) => {
    let currentLatitude = statusResponse?.providers[0]?.currentLatitude;
    let currentLongitude = statusResponse?.providers[0]?.currentLongitude;
    let eventHistory = statusResponse?.providers?.[0]?.eventHistory;
    const isTwoWayTow = checkIfTwoWayTow();
    if (eventHistory?.[0]?.eventType === CANCELLED || eventHistory?.[0]?.eventType === CANCELED || rescueStatus === DROP_PASSG || (isTwoWayTow && rescueStatus === DROP_SIGN_RSV)) {
      if (eventHistory?.length === 1) {
        rescueStatus = BOOKED;
      } else {
        rescueStatus = eventHistory.find((event, index) => (index !== 0 && event.eventType !== CANCELLED && event.eventType !== CANCELED
          && event.eventType !== DROP_PASSG && event.eventType !== DROP_SIGN_RSV && event.eventType !== GPS))?.eventType;
        currentLatitude = eventHistory?.[0]?.eventLatitude;
        currentLongitude = eventHistory?.[0]?.eventLongitude;
      }
    }
    if(rescueStatus === GONE_ON_ARRIVAL_RSV){
      return <GoneOnArrival/>
    }
    let trackeringSteps = determineRescueTitlesAndStatusMessaging(true);
    let trackerSteps = trackeringSteps.map(o => {return {...o, rescueStatus: `notcomplete`}}); //reseting status to not complete
    const trackerLen = trackerSteps.length;
    const result = trackerSteps.filter(ele => ele.servicestatus.includes(rescueStatus));
    let index = 0
    if (result?.length) {
      for (index; index < trackerSteps.length; index++) {
        if (trackerSteps[index].servicestatus.includes(rescueStatus)
            && (index !== trackerLen-1)) {
          trackerSteps[index].status = "inprogress";
          break;
        } else {
          trackerSteps[index].status = "complete";
        }
      }
    }

    const showETA = () => {
      if (rescueStatus === DROP_SIGN_RSV && !isTwoWayTow) {
        return false;
      }
      else return !ETA_NOT_REQUIRED_STATUSES.includes(rescueStatus);
    }
    const inProgressStep = trackerSteps[trackerLen-1].status === "complete" ? trackerSteps[trackerLen-1] : trackerSteps.filter(step => step.status === 'inprogress').pop();
    let etaMinutes;
    const etaType = statusResponse.projectedETA?.projectedETAType;
    if(!inProgressStep) datadogRum.addAction("No in-progress step", {usageId})
    if (inProgressStep && etaType && ((!isTwoWayTow && isETAMessageBeShown) || isTwoWayTow || rescueStatus === BOOKED || rescueStatus === ASSIGNED) && showETA()) {
      etaMinutes = statusResponse.projectedETA.projectedEtaMinute;
      etaMinutes = etaMinutes === 1 ? `${etaMinutes} minute` : `${etaMinutes} minutes`;
      const descUpdateResult = updatePredictiveMessagingConstant(etaType, rescueStatus, isTwoWayTow)?.replace("{projectedETAMinute}", etaMinutes);
      if (descUpdateResult) {
        inProgressStep.desc = descUpdateResult;
      }
    }

    let home = {
      pos: {
        lat: "",
        lng: "",
      },
    };

    let truckLo = {
      pos: {
        lat: "",
        lng: "",
      },
    };

    let destinationLocation = {
      lat: "",
      lng: "",
    };

    if (
      statusResponse?.providers[0] &&
      currentLatitude &&
      currentLongitude
    ) {
      truckLo.pos.lat = currentLatitude;
      truckLo.pos.lng = currentLongitude;
      home.pos.lat = statusResponse.addresses[0].latitude;
      home.pos.lng = statusResponse.addresses[0].longitude;

      if (statusResponse.addresses[1]) {
        destinationLocation.lat = statusResponse.addresses[1].latitude;
        destinationLocation.lng = statusResponse.addresses[1].longitude;
      }
    }
    return (
      <div className="l-tracker-container">
        <h4 className="c-title"
              id="tracking-your-rescue">{getTranslatedText(TRANSLATION_CONSTANTS.TRACKING_YOUR_RESCUE)}</h4>
          <div className="c-clock">
            <p className="c-clockTime">{getUpdatedClock(getTranslatedText(TRANSLATION_CONSTANTS.CLOCK_UPDATED))}</p>
          </div>
        <div className="l-tracker-section">
          {(inProgressStep ||
            isProviderCancelClicked) &&
            <React.Fragment>
              <div className="c-eta-steps">
                <span>{getTranslatedText(TRANSLATION_CONSTANTS.RESCUE_STEP)}: </span>
                <span
                  data-testid="stepsComplete">{`${index >= trackerLen ? index : index + 1} of ${trackerLen}`}
                </span>
              </div>
              {showETA() &&
              <div className="c-eta">
                {(statusResponse.projectedETA ||
                  isProviderCancelClicked || smsRescueTrackerStatus === NEW_JOB_RSV) ?
                  <>
                    <Icon icon={ClocktimeF} color="gray-dark" size={1}/>
                    {smsRescueTrackerStatus === NEW_JOB_RSV ?
                      (<p className="c-waitTime"> {!isEmpty(predictiveETA) ? (`${getTranslatedText(TRANSLATION_CONSTANTS.ETA)}: ${predictiveETA?.petaFrom} - ${predictiveETA?.petaTo}`) : getTranslatedText(TRANSLATION_CONSTANTS.ETA_CALCULATING)} </p>) :
                      (<p className="c-waitTime">{displayETA(statusResponse, getTranslatedText, true)}</p>)}
                  </>
                  : null
                }
              </div>
              }
              <div className="c-trackerlist c-trackerlist__container">
                {trackerSteps.map((trackerStep, i) => {
                  let lastClass = false;
                  if (trackerLen === i + 1) {
                    lastClass = true;
                  }
                  return (
                    <div
                      key={i}
                      className="c-trackerlist__trackerStep"
                    >
                      <div
                        className={`c-trackerlist__item ${trackerStep.status}`}
                      >
                        <div
                          id={`track-status-icon-${i}`}
                          className="c-trackerlist__icon"
                        >
                          <TrackStatus
                            id={`track-status-${i}`}
                            number={i + 1}
                            status={trackerStep.status}
                            lastItem={lastClass}
                          />
                        </div>
                      </div>
                      <div
                        className={((trackerStep.status === 'complete' || trackerStep.status === 'notcomplete') &&
                        smsRescueTrackerStatus !== COMPLETED) ? 'c-complete-text' : 'c-inprogress-text'}>
                        {getTranslatedText(trackerStep.shortTitle)}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="c-divider"/>
              <div className="c-trackerlist">
                {inProgressStep ?
                  <div
                    id='track-status-text'
                    className='c-trackerlist__text completed'
                  >
                    <p data-testid="stepTitle"
                      className="c-trackerlist__text__title">
                      {getTranslatedText(inProgressStep.shortTitle)}
                    </p>
                    <p data-testid="stepDesc" className="c-trackerlist__text__description">
                      {getTranslatedText(inProgressStep.desc).replace(/{(\w*)}/g, etaMinutes)}
                    </p>
                  </div>
                  : null
                }
              </div>
            </React.Fragment>
          }
          {statusResponse?.providers[0] && statusResponse?.providers[0].name ? (
            <div>
              <ProviderInfo
                companyName={statusResponse?.providers[0].name}
                contactNumber={statusResponse?.providers[0].contactNumber}
                linkType={linkType}
              />
            </div>
          ) : null
          }
          {currentLatitude &&
          currentLongitude
          && !(rescueStatus === EN_ROUTE && (etaType === CALCULATED_NO_GPS || etaType === DATA_DRIVEN_ETA))
          && rescueStatus !== BOOKED
          && rescueStatus !== ASSIGNED
          && rescueStatus !== COMPLETED ? (
            <div>
              <div className="c-divider"/>
              <TrackerMap
                myLocation={home.pos} //Testing positions - Current Location from redux to go here.
                towTruckLocation={truckLo.pos} //Testing positions - Tow Truck co-ords to go here.
                destinationLocation={destinationLocation}
                mapOptions={{
                  class: "tracker",
                  zoom: 4,
                  streetViewControl: false,
                  scrollwheel: false,
                  draggable: true,
                  mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                  mapTypeControl: false,
                  zoomControl: false,
                  fullscreenControl: false,
                  center: {
                    lat: 39.82835,
                    lng: -98.5816684,
                  },
                  streetViewControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_CENTER,
                  },
                  styles: [
                    {
                      featureType: "poi",
                      stylers: [{visibility: "off"}],
                    },
                  ],
                }}
              />
            </div>
          ) : null}
          { rescueInProgress ?
              <>
                <div className="share-rescue-block l-grid l-grid__col">
                  <div className="l-grid__col l-grid__col--9">
                    <p className={'share-rescue-text'}>{getTranslatedText(TRANSLATION_CONSTANTS.FAMILY_AND_FRIENDS)}</p>
                  </div>
                  <div className="l-grid__col l-grid__col--3">
                    <ShareButton shareRescueOnClick={shareRescueOnClick}/>
                  </div>
                </div>
              </>
              : null}
        </div>
        {(linkType === RESCUE_CUSTOMER_VERSION || RESCUE_WLES.includes(linkType)) &&  !STATUS_FOR_HIDE_CANCEL.includes(smsRescueTrackerStatus) ?
          renderCancelRescueSection() : null}
      </div>
    );
  };

  const openLyft = () => {
    window.open("https://lyft.com/ride?id=lyft&partner=ODC8ns6F-IQI");
  }

  const rescueStatus = smsRescueTrackerStatus
  const rescueInProgress = (linkType === RESCUE_CUSTOMER_VERSION || RESCUE_WLES.includes(linkType))
  const isApple = /webOS|iPhone|iPad|iPod/.test(navigator.userAgent);
  const mobileStoreHref = isApple ? partnerDetails.iosAppUrl : partnerDetails.androidAppUrl
  const storeLogo = isApple ? 'apple-app-store' : 'google-play-store';
  const showMobileAppLink = !!partnerDetails.iosAppUrl && partnerDetails.appTitle
  let overCostAmount;
  if (
    statusResponse?.overCost &&
    statusResponse?.overCost?.costOverBenefitLimitAmount
  ) {
     overCostAmount = statusResponse?.overCost?.costOverBenefitLimitAmount;
  }

  return (
    <>
      <div className="c-title" id="tracking-your-rescue" style={{color: "#f3f6f9"}}>Track Your Rescue</div>
      <RenderShareModal
        openShareModal={openShareModal}
        setOpenShareModal={() => setOpenShareModal(false)}
        closeModal={() => setOpenShareModal(false)}
        textHref={textHref}
        getTranslatedText={getTranslatedText}
        usageId={usageId}
        partnerCode={partnerCode}
      />
      {isLoading ? <Spinner/> : null}
      {overCostAmount && <OvercostWarning amount={overCostAmount}/>}
      {!isLoading ?
      <div role="main" className="l-container">
        {rescueCancelled ? <Alert type='info' alertMessages={[getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_CANCELLED)]} className='rescue-tracker-alert'/> : null}
        {showCancellationError ? <Alert type='error' alertMessages={[getTranslatedText(TRANSLATION_CONSTANTS.CANCEL_ERROR)]} className='rescue-tracker-alert'/> : null}
        {showRescueStatusErrorWithNoPreviousSuccess || errorCount > 5  ? <Alert type='error' alertMessages={[getTranslatedText(TRANSLATION_CONSTANTS.RESCUE_STATUS_ERROR).replace('{timeLeft}', timeLeft)]} className='rescue-tracker-alert'/> : null}
        {rescueCancelled && partnerDetails.customerServiceHelpApp ? <p>{getTranslatedText(TRANSLATION_CONSTANTS.HELP_TEXT).replace('{appName}', partnerDetails.customerServiceHelpApp)}</p> : null}
        {!rescueCancelled ? renderTrackRescueSection(rescueStatus): null}
        {amazonConnectScriptLoaded && <Grid.Column
          col={{ sm: 10, md: 2, lg: 2, xl: 2 }}
          offset={{ sm: 1, md: 5, lg: 5, xl: 5 }}
        >
          <Button
            size='lg'
            hasUpgrade
            id="ars_chat"
            onClick={() => {
              if(amazonConnectScriptLoaded) getVaChat().open();
            }}
            isLoading={!amazonConnectScriptLoaded}
            data-dd-privacy="mask"
            utils={{
              fullWidth: true
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.LIVE_CHAT)}
          </Button>
        </Grid.Column>}
        {rescueStatus ? renderRequestSummarySection() : null}
        {showMobileAppLink ?
          <ImageLink
            href={mobileStoreHref}
            imageAltText={`${partnerDetails.appTitle} Mobile App`}
            imageSrc={`/images/${storeLogo}.png`}
            label={getTranslatedText(TRANSLATION_CONSTANTS.MOBILE_APP_LABEL).replace('{appName}', partnerDetails.appTitle)}
          />
          : null}
        {((partnerDetails?.showLyftSection || experience?.showLyftSection) && rescueInProgress ?
          <>
            <div className="l-tracker-container" data-testid="lyftSection">
              <div className="c-lyftSection">
                <div className="c-lyftSection--content">
                  <div className="c-lyftSection--title">
                    <h3>{getTranslatedText(TRANSLATION_CONSTANTS.SCHEDULE_A_RIDE)}</h3>
                  </div>
                  <div className="c-lyftSection--main">
                    <p>{getTranslatedText(TRANSLATION_CONSTANTS.HAVE_LYFT_READY)}</p>
                  </div>
                </div>
                <div className="c-shareSection--button">
                  <Lyft handleClick={openLyft}/>
                </div>
              </div>
            </div>
          </>
          : null)}
        {partnerCode === PEP && !statusResponse.towService &&
          (<div className="l-confirmation-container l-confirmation-container--couponOffer">
            <Slab
              size="sm"
              borderLeft="default"
              icon={GiftIcon}
              title={<Type variant="darker" weight="bold" size="2xl">{getTranslatedText(TRANSLATION_CONSTANTS.PEP_BOYS_OFFERS)}</Type>}
              subtitle={<Type size="md" lineHeight="md">{getTranslatedText(TRANSLATION_CONSTANTS.PEP_BOYS_COUPON)}</Type>}
              onClick={() => {
                datadogRum.addAction(`${displayCode} Coupon link clicked`);
                window.open("https://allstate.com/myoffers/pepboys", "_blank")
              }}
            />
          </div>)
        }
        { partnerCode === 'PEP' ? <CreditCardOffer displayCode={displayCode}/> : null}
          {!checkIfTwoWayTow() && <RentalExchange />}
      </div>
      : null}
    </>
  );
}

export const displayETA = (response, getTranslatedText, isSMSRT) => {
  const projectedETA = isSMSRT ? response.projectedETA : response;
  let finalProjectedEtaTime = formatTimeForRescueTracker(isSMSRT ? projectedETA?.projectedEtaTime : projectedETA?.projectedETATime);
  if (finalProjectedEtaTime === 'Invalid Date') {
    datadogRum.addError(new Error("Invalid projectedEtaTime Encountered"), projectedETA, undefined);
    finalProjectedEtaTime = "";
  }
  if (projectedETA?.projectedEtaTimeMax || projectedETA?.projectedETATimeMax) {
    const finalProjectedEtaTimeMax = formatTimeForRescueTracker(isSMSRT ? projectedETA?.projectedEtaTimeMax : projectedETA?.projectedETATimeMax);
    if (finalProjectedEtaTimeMax === 'Invalid Date') {
      datadogRum.addError(new Error("Invalid projectedEtaTimeMax Encountered"), projectedETA, undefined);
      finalProjectedEtaTimeMax = "";
    }
    return getTranslatedText(TRANSLATION_CONSTANTS.ETA) + ": " + finalProjectedEtaTime + " - " + finalProjectedEtaTimeMax;
  } else {
    const projectedEtaMinutes = isSMSRT ? projectedETA?.projectedEtaMinute : projectedETA?.projectedETAMinute;
    return getTranslatedText(TRANSLATION_CONSTANTS.ETA) + " : " + finalProjectedEtaTime + " | " + projectedEtaMinutes + ` min${projectedEtaMinutes > 1 ? 's' : ''}`;
  }
}

export const RenderShareModal = ({
  openShareModal,
  setOpenShareModal,
  closeModal,
  textHref,
  getTranslatedText,
  usageId,
  partnerCode,
  sessionId,
  rtName,
  language
  }) => {
  const sendEmail = async () => {
    setOpenShareModal();
    const friendsAndFamilyShareUrl = await createShareShortUrl(usageId, partnerCode, sessionId, rtName, language);
    window.location = "mailto:?subject=Track My Rescue Now &body=Track My Rescue Now "+ friendsAndFamilyShareUrl;
  };

  return (
    <ModalDialog
      id="share-rescue-modal"
      role="dialog"
      footer={false}
      isPrompt={false}
      hideTrigger
      className={'share-rescue-modal'}
      showCloseBtn={true}
      isActive={openShareModal}
      onClose={closeModal}
      >
      <span className="btn btn--tertiary anchor--btn">
        <a href={textHref}>
          <span className="inner-anchor-button">
            <img  width={30} src={'/images/text-icon.png'} alt='Share rescue tracker via text'/>
            <span className='inner-anchor-button-text'>{getTranslatedText(TRANSLATION_CONSTANTS.TEXT)}</span>
          </span>
        </a>
      </span>
      <button className="btn btn--tertiary" onClick={sendEmail}>
        <img width={30} src={'/images/email-icon.png'} alt='Share rescue tracker via email'/>
        <p>Email</p>
      </button>
    </ModalDialog>
  )
}

export const createShareShortUrl = async (usageId, partnerCode, sessionId, rtName, language) => {
  const sanitizedUsageId = allowNumbers(usageId);
  const longUrl = rtName === "WLE" ? window.location.origin + "/wd/f/" + partnerCode + "/" + sessionId + "/" + sanitizedUsageId +
  (language ? `/${language}` : "/en_US") : window.location.href.replace('/wd/l', '/wd/f');
  const sanitizedLongUrl = allowValidUrlCharacters(longUrl);
  const shortUrl = await getShortenedUrl(partnerCode, sanitizedUsageId, sanitizedLongUrl);
  return shortUrl || longUrl;
};

export default RescueTracker
