export const getServiceRequestEmail = (state) =>
  state?.serviceRequestPayload.serviceRequest.email || "";

export const getServiceFlatTirePart = (state) =>
  state?.serviceRequestPayload.serviceRequest?.serviceTypeOptions?.tire
    .replacementTire || "";

export const getIsTireDamaged = (state) =>
  state?.serviceRequestPayload?.serviceRequest?.serviceTypeOptions?.tire
    ?.visiblyDamaged;
