import {combineReducers} from 'redux'
import ServiceInfo from './reducer-service-info'
import CurrentLocation from './reducer-currentlocation'
import Destination from './reducer-destination'
import NearByDestinations from './reducer-near-by-destinations'
import VehicleValue from './reducer-vehicle-value'
import ServiceDetails from './reducer-service-details';
import MakeModel from './reducer-makemodel';
import SubmitRequest from './reducer-submitrequest';
import PartnerDetails from './reducer-partner-details';
import SelectedMakeModelYearProp from './reducer-selectedMakemodelYear';
import ServiceRequestInfo from './reducer-serviceRequestInfo';
import ProviderInfo from './reducer-providerInfo';
import EpcsInitialize from './reducer-epcs-initialize';
import EpcsStatus from './reducer-epcs-status';
import CommonBenefits from './reducer-common-benefit';
import PepSession from './reducer-pep-session';
import ServiceRequestPayload from './reducer-service-request-payload';
import ServiceTransition from './reducer-serviceTransition';
import GeneralInfo from './reducer-general-info';
import ServiceTime from './reducer-service-time';
import DriverList from './reducer-driver-list';
import IncidentReportFormReducer from "./reducer-incident-report-form";

const baseReducer = combineReducers({
  serviceTransition: ServiceTransition,
  serviceRequestPayload: ServiceRequestPayload,
  serviceInfo: ServiceInfo,
  currentLocation: CurrentLocation,
  destination: Destination,
  nearByDestinations: NearByDestinations,
  vehicleValue: VehicleValue,
  serviceDetails: ServiceDetails,
  makemodel : MakeModel,
  submitRequest : SubmitRequest,
  serviceTime: ServiceTime,
  serviceRequestInfo : ServiceRequestInfo,
  providerInfo : ProviderInfo,
  selectedMakeModelYearProp : SelectedMakeModelYearProp,
  partnerDetails : PartnerDetails,
  epcsInitialize : EpcsInitialize,
  epcsStatus : EpcsStatus,
  commonBenefits: CommonBenefits,
  pepSession:PepSession,
  generalInfo: GeneralInfo,
  driverList: DriverList,
  incidentReportForm: IncidentReportFormReducer
});

export default baseReducer;
