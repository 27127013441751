import React, { Component } from 'react';
import { connect } from 'react-redux';
import CONSTANTS, { SUSPENDED_VEHICLE_TO_CALL_CENTER, SERVICE_LIMIT, SERVICE_UNAVAILABLE } from '../../app-consts/appConstants';
import RoadsideAssist from "../../components/svg/RoadsideAssist";
import { datadogRum } from "@datadog/browser-rum";
import { USER_OUT_OF_CLAIMS } from '../../app-consts/errorMessageConstants';
import { LocaleConsumer, LocaleContext } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { Button } from 'mesh-component-library';
export class ServiceTransition extends Component {
  static contextType = LocaleContext;

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  callCustomerService = () => {
    const { partnerDetails, serviceTransition } = this.props;
    datadogRum.addAction(`ServiceTransition: Call for Assistance clicked for service: ${serviceTransition?.serviceType}, appID: ${partnerDetails.partnerDetails?.appId}`)
    const phone = partnerDetails.partnerDetails?.phone || localStorage.getItem('callbackNumber');
    window.location.href = 'tel:' + phone;
  }

  determineError = () => {
    const getTranslatedText = this.context;
    const { commonBenefits, serviceTransition, partnerDetails } = this.props;
    const {
      errorMessageContent,
      declineType,
      serviceType,
    } = serviceTransition;

    const { errorMessage } = commonBenefits;
    let title, content;

    if (errorMessageContent?.title) {
      if (errorMessageContent.title === USER_OUT_OF_CLAIMS.title) {
        datadogRum.addAction('ServiceTransition: Out of Claims', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      }
      title = errorMessageContent.title
      content = errorMessageContent.message
    } else if (declineType === SERVICE_UNAVAILABLE) {
      datadogRum.addAction(`ServiceTransition: Service unavailable for ${serviceType}`, { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = `${getTranslatedText(TRANSLATION_CONSTANTS.WE_CANNOT_SERVICE_TITLE)} ${serviceType} ${getTranslatedText(TRANSLATION_CONSTANTS.REQUEST)} `;
      content = getTranslatedText(TRANSLATION_CONSTANTS.CAN_STILL_HELP);
    } else if (declineType === SERVICE_LIMIT) {
      datadogRum.addAction('ServiceTransition: Service Limit Reached', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = `${getTranslatedText(TRANSLATION_CONSTANTS.SORRY_YOU_HAVE_REACHED)} ${serviceType} ${getTranslatedText(TRANSLATION_CONSTANTS.LIMIT)} `;
      content = getTranslatedText(TRANSLATION_CONSTANTS.CAN_STILL_HELP_THANK);
    } else if (errorMessage === CONSTANTS.MEMBER_NOT_FOUND_MESSAGE) {
      datadogRum.addAction('ServiceTransition: Member Not Found', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = getTranslatedText(TRANSLATION_CONSTANTS.SORRY_NOT_FIND_BENEFITS);
      content = getTranslatedText(TRANSLATION_CONSTANTS.CAN_STILL_HELP);
    } else if (errorMessage === CONSTANTS.INVALID_MVA_NUMBER) {
      datadogRum.addAction('ServiceTransition: Unable to fetch benefits for Motor vehicle Number', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = getTranslatedText(TRANSLATION_CONSTANTS.WE_ARE_SORRY);
      content = `${getTranslatedText(TRANSLATION_CONSTANTS.MOTOR_VEHICLE_ALERT_MESSAGE)} ${partnerDetails?.partnerDetails.brand} ${getTranslatedText(TRANSLATION_CONSTANTS.FURTHER_ASSISTANCE)} `
    } else if (errorMessage === CONSTANTS.OUT_OF_CLAIMS_MESSAGE) {
      datadogRum.addAction('ServiceTransition: Out of Claims', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = getTranslatedText(TRANSLATION_CONSTANTS.NO_BENEFITS_AVAILABLE);
      content = getTranslatedText(TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS);
    } else if (errorMessage === CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS || declineType === CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS) {
      datadogRum.addAction('ServiceTransition: Weight Class', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE_WEIGHT_LIMIT);
      content = getTranslatedText(TRANSLATION_CONSTANTS.WEIGHT_CLASS_SPECIAL_SERVICE);
    } else if (declineType === SUSPENDED_VEHICLE_TO_CALL_CENTER) {
      datadogRum.addAction('ServiceTransition: Tow request for suspended vehicle ', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = getTranslatedText(TRANSLATION_CONSTANTS.WE_ARE_SORRY);
      content = getTranslatedText(TRANSLATION_CONSTANTS.SUSPENDED_VEHICLE_TO_CALL_CENTER).replace('{serviceType}', getTranslatedText(TRANSLATION_CONSTANTS[serviceType?.toUpperCase().replace(' ', '_')]));
    } else {
      datadogRum.addAction('ServiceTransition: Default', { appId: partnerDetails.appId, memberId: commonBenefits.memberId })
      title = getTranslatedText(TRANSLATION_CONSTANTS.RUN_INTO_ISSUES_BENEFITS);
      content = getTranslatedText(TRANSLATION_CONSTANTS.CAN_STILL_HELP);
    }

    return { title, content }
  }

  render() {
    const { title, content } = this.determineError()

    return (
      <LocaleConsumer>
        {(getTranslatedText) => (
          <div id='service-transition' className="c-container-declined">
            <div className="c-container-declined__title">
              <h1 className="u-vr-3">{title}</h1>
              <div className="u-vr-6">{content}</div>
            </div>
            {this.props?.commonBenefits?.errorMessage !== CONSTANTS.INVALID_MVA_NUMBER ?
              <Button
                size='lg'
                hasUpgrade
                id="service-transition-button-to-call"
                onClick={this.callCustomerService}
                utils={{
                  fullWidth: true
                }}>
                {getTranslatedText(TRANSLATION_CONSTANTS.CALL_FOR_ASSISTANCE)}
              </Button>
              :
              null
            }
            <div
              className="c-container-declined__svg u-vr-4 u-vr-6-top u-flex u-flex--center">
              <RoadsideAssist />
            </div>
            <div className="c-divider" />
          </div>
        )}
      </LocaleConsumer>
    )
  }
}

function mapStateToProps(state) {
  return {
    partnerDetails: state.partnerDetails,
    commonBenefits: state.commonBenefits,
    serviceTransition: state.serviceTransition
  };
}

export default connect(mapStateToProps, {})(ServiceTransition);
