import React, { useEffect, useRef, useState } from "react";
import { saveRescueDetails } from "../../api";
import { isEmpty } from "lodash";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import CONSTANTS, {
  FUEL_SERVICE_ID,
  TIRE_CHANGE_ID,
  DISPLAY_TRIAGE_STEPS_STATUSES,
  TOW,
} from "../../app-consts/appConstants";
import { TimelineMax } from "gsap";
import { iconToCheckAnimation } from "./animationHelper";
import { Button } from "mesh-component-library";
import {
  CustomerSupportIcon,
  CreditCardIcon,
  CarIcon,
  ReportIcon,
} from "../../assets";
import Icon from "../icon";
import SelectablePanel from "../selectable-panel";
import renderIcon from "../../utilities/render-icon";
import {
  colorTranslatedText,
  serviceTranslatedText,
} from "../../utils/translatedTextUtils";
import titleCase from "../../utils/titleCase";
import { sendVehicleToJacada } from "../../jacada/sendJacadaEventData";
import {
  generatePath,
  generatePathWithSearchParams,
} from "../../utilities/RedirectUtils";
import { datadogRum } from "@datadog/browser-rum";
import Spinner from "../spinner";
import { isScheduleService } from "../../utils/serviceRequestPayloadUtils";
import { sendJacadaRequest } from "../../api";

export const ServiceDetailsOverlay = ({
  commonBenefits,
  epcsStatus,
  isPepPartner,
  makemodel,
  partnerCode,
  partnerDetails,
  serviceInfo,
  serviceRequestPayload,
  updateVehicleInfo,
  selectedMakeModelYear,
  submitServiceRequest,
  updatedEpcsStatus,
  refreshServiceRequestInfo,
  updatePpuFlag,
  updateLoggedInFlag,
  updateCCardInfo,
  updateVehicle,
  updateServiceTypeOptions,
  updatePpuFuelPrice,
  submitRequest,
  incidentReportFormComplete,
  ...props
}) => {
  const getTranslatedText = useLocale();

  const [showSpinner, setShowSpinner] = useState(false);
  const [paymentText, setPaymentText] = useState(
    getTranslatedText(TRANSLATION_CONSTANTS.ADD_CONTACT)
  );
  const [formComplete, setFormComplete] = useState(false);
  const [vehicleDetailsText, setVehicleDetailsText] = useState(
    getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_VEHICLE)
  );
  const [incidentReportText, setIncidentReportText] = useState(
    getTranslatedText(TRANSLATION_CONSTANTS.COMPLETE_INCIDENT_REPORT)
  );
  const carIconAnimation = new TimelineMax({ paused: true });
  const creditCardAnimation = new TimelineMax({ paused: true });
  const reportIconAnimation = new TimelineMax({ paused: true });
  const hasMounted = useRef(false);
  const isABGPartner =
    partnerDetails.partnerCode == "CRA" ||
    partnerDetails.partnerCode == "PCR" ||
    partnerDetails.partnerCode == "BCA";

  useEffect(() => {
    let vehicleInfo = {};
    if (
      serviceInfo.id === FUEL_SERVICE_ID &&
      commonBenefits?.vehicles?.length === 1 &&
      commonBenefits.vehicles[0].color &&
      commonBenefits.vehicles[0].fuelType &&
      commonBenefits.vehicles[0].fuelType?.toLowerCase() !== "null" &&
      !(
        serviceRequestPayload.serviceRequest.vehicle ||
        serviceRequestPayload.serviceRequest.vehicles
      )
    ) {
      vehicleInfo = { ...commonBenefits.vehicles[0] };
      updateServiceTypeOptions({
        gas: titleCase(vehicleInfo.fuelType),
      });
      updateVehicle(vehicleInfo);
    } else {
      vehicleInfo =
        serviceRequestPayload.serviceRequest.vehicle ||
        serviceRequestPayload.serviceRequest.vehicles;

      if (
        serviceInfo.id === FUEL_SERVICE_ID &&
        !serviceRequestPayload.serviceRequest.serviceTypeOptions?.gas
      ) {
        vehicleInfo = {};
      }
    }
    if (vehicleInfo?.year) {
      carIconAnimation.play(0, false);

      setVehicleDetailsText(
        `${vehicleInfo?.year} ${getTranslatedText(
          colorTranslatedText(titleCase(vehicleInfo?.color))
        )} ${vehicleInfo?.make} ${vehicleInfo?.model}`
      );

      sendVehicleToJacada(vehicleInfo);
      if (partnerDetails.omniStreamlined && serviceInfo.service !== "gas") {
        saveRescueDetails(
          serviceRequestPayload,
          partnerDetails.partnerCode,
          serviceInfo.service
        );
      }
    }
    setPaymentText(getTranslatedText(TRANSLATION_CONSTANTS.CONTACT_PAYMENT));

    refreshServiceRequestInfo();
    if (
      isPepPartner ||
      commonBenefits.benefits ||
      partnerDetails.experience?.passThruEnabled
    ) {
      setPaymentText(getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_CONTACT));
    }
    if (
      epcsStatus.completed &&
      serviceRequestPayload.serviceRequest.firstName
    ) {
      let displayName = titleCase(
        serviceRequestPayload.serviceRequest.firstName +
          " " +
          serviceRequestPayload.serviceRequest.lastName
      );
      setPaymentText(displayName);
      creditCardAnimation.play(0, false);
    }
  }, [serviceRequestPayload, commonBenefits]);

  useEffect(() => {
    if (incidentReportFormComplete) {
      setIncidentReportText(
        getTranslatedText(TRANSLATION_CONSTANTS.COMPLETED_INCIDENT_REPORT)
      );
      reportIconAnimation.play(0, false);
    }
    iconToCheckAnimation(
      carIconAnimation,
      creditCardAnimation,
      reportIconAnimation
    );
  }, []);

  useEffect(() => {
    const {
      vehicle,
      vehicles,
      firstName,
      lastName,
      contactNumber,
      CCardInfo,
      ppuFlag,
      serviceTypeOptions,
      email,
    } = serviceRequestPayload.serviceRequest;
    let rescueVehicle = vehicle || vehicles;
    let vehicleInfoFilled =
      !!rescueVehicle?.color &&
      !!rescueVehicle?.make &&
      !!rescueVehicle?.model &&
      !!rescueVehicle?.year;
    let personalInfoFilled = !!firstName && !!lastName && !!contactNumber;
    if (ppuFlag === "Y" && partnerDetails.experience.ppuEnabled) {
      personalInfoFilled =
        personalInfoFilled &&
        email &&
        CCardInfo?.maskedCardNumber &&
        CCardInfo?.billingZipCode;
    }
    if (serviceInfo.id === FUEL_SERVICE_ID) {
      vehicleInfoFilled = vehicleInfoFilled && serviceTypeOptions?.gas;
    }
    const displayIncidentReport =
      isABGPartner && serviceInfo.id === TIRE_CHANGE_ID;
    const formReady = !displayIncidentReport
      ? vehicleInfoFilled && personalInfoFilled
      : incidentReportFormComplete && vehicleInfoFilled && personalInfoFilled;
    setFormComplete(formReady);
  }, [serviceRequestPayload]);

  useEffect(() => {
    if (!hasMounted.current) {
      redirectPage();
      submitPersonalInfo();
      if (
        partnerDetails.omniStreamlined &&
        serviceInfo.service === "gas" &&
        (serviceRequestPayload.serviceRequest.vehicle ||
          serviceRequestPayload.serviceRequest.vehicles) &&
        serviceRequestPayload.serviceRequest.serviceTypeOptions
      ) {
        saveRescueDetails(
          serviceRequestPayload,
          partnerDetails.partnerCode,
          serviceInfo.service
        );
      }
    }
  });

  const handleConfirmVehicleClick = () => {
    if (commonBenefits.benefits) {
      props.history.push(generatePath("confirm-vehicle"));
      datadogRum.addAction("Vehicle Select Modal Clicked", undefined);
    } else {
      props.history.push(generatePath("add-vehicle"));
      datadogRum.addAction("Vehicle Information Modal Clicked", undefined);
    }
  };

  const handleSubmit = () => {
    if (
      commonBenefits.vehicles?.find(
        (vehicle) =>
          vehicle?.vehicleID ===
          serviceRequestPayload.serviceRequest?.vehicles?.consumervehicleid
      )?.suspendIndicator === "Y"
    ) {
      updatePpuFuelPrice(0);
    }
    datadogRum.addAction("Submit Rescue Clicked");
    if (showSpinner === false) {
      setShowSpinner(true);
    }
    submitServiceRequest(serviceRequestPayload);
  };

  const handleIncidentReportClick = () => {
    props.history.push(generatePath("incident-report"));
  };

  const openPaymentModal = () => {
    props.history.push(generatePath("payment"));
  };

  const submitPersonalInfo = () => {
    if (!isEmpty(epcsStatus.response) && !epcsStatus.completed) {
      creditCardAnimation.play(0, false);
      updatedEpcsStatus();
    }
  };

  const amountDue = () => {
    if (
      DISPLAY_TRIAGE_STEPS_STATUSES.includes(commonBenefits.status) &&
      commonBenefits?.status?.toUpperCase() ===
        CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO
    ) {
      return serviceInfo?.cost ? "$" + serviceInfo?.cost : "";
    } else {
      if (
        serviceRequestPayload.hasPepSession &&
        serviceInfo.serviceText === TOW
      ) {
        return "$" + destination?.towInfo?.totalTowCost;
      } else {
        const transactionAmount =
          serviceRequestPayload.serviceRequest.CCardInfo?.transactionAmount;
        return transactionAmount ? "$" + transactionAmount : "";
      }
    }
  };

  //TODO: Once Navigation actions are implemented, this should be moved to Sagas
  const redirectPage = () => {
    if (submitRequest.success) {
      let sessionId, callId, requestId, usageId;
      if (partnerDetails.omniStreamlined) {
        sessionId = localStorage.getItem("benefitSession");
        callId = sessionId;
        usageId = localStorage.getItem("usageId");
      } else {
        sessionId = submitRequest.response.sessionId;
        callId = submitRequest.response.callId;
        requestId = submitRequest.response.requestId;
        usageId = submitRequest.response.usageId;
      }
      if (partnerDetails.route === "OmniAssist") {
        sendJacadaRequest({
          externalSystemId: localStorage.getItem("conversationId"),
          events: [
            {
              name: "call_id",
              value: callId,
            },
          ],
        });
      }

      localStorage.setItem("requestId", requestId);
      localStorage.setItem("submitRequest", JSON.stringify(submitRequest));

      if (isScheduleService(serviceRequestPayload)) {
        props.history.push(`/appointment-confirmation?id=${usageId}`);
      } else if (partnerDetails.omniStreamlined) {
        props.history.push(
          generatePathWithSearchParams(
            `/wd/l/${partnerCode}/${sessionId}/${usageId}`
          )
        );
      } else {
        props.history.push(
          generatePath(`tracker/${sessionId}/${callId}/${requestId}/${usageId}`)
        );
      }
    }
    if (makemodel.error || submitRequest.fail) {
      let failureType;
      let response;
      if (makemodel.error) {
        failureType = "HowEmbarrassing-MakeModel";
        response = makemodel.payload;
      } else if (submitRequest.fail) {
        failureType = "HowEmbarrassing-ServiceRequest";
        response = submitRequest.response;
      }
      datadogRum.addError(
        `Failure Type: ${failureType}, Response: ${response}`
      );
      props.history.push(generatePath("sitedown"));
    }
  };

  return (
    <>
      <div className="vehiclepaycontainer">
        {showSpinner && <Spinner />}

        <div className="l-grid__col">
          <div className="c-selected-service">
            <Icon
              className="c-selected-service__icon"
              icon={renderIcon(serviceInfo.serviceText)}
              size={1.5}
              color="brand"
            />
            <h5 className="c-selected-service__type">
              {getTranslatedText(
                serviceTranslatedText(serviceInfo.serviceText)
              )}
            </h5>
            <h4 className="c-selected-service__price">{amountDue()} </h4>
          </div>
        </div>

        <div className="l-grid__col">
          <SelectablePanel
            text={vehicleDetailsText}
            id="vehicle-info"
            className="vehiclepanel"
            name="radiooptions"
            ariaLabel={vehicleDetailsText}
            value="vehiclepanel"
            type="button"
            role="link"
            serviceclass="vehicle-item-1"
            metaMessage=""
            location={true}
            onClick={() => {
              handleConfirmVehicleClick();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleConfirmVehicleClick();
              }
            }}
          >
            <CarIcon />
            <Icon
              icon="chevron"
              color="brand"
              size={1.2}
              className="icon-rotation-reverse vehicle-item-3"
            />
          </SelectablePanel>
        </div>

        <div data-dd-privacy="mask" className="l-grid__col">
          <SelectablePanel
            text={paymentText}
            id="payment-info"
            className="paymentpanel"
            name="radiooptions"
            ariaLabel="Contact or Payment Info"
            value="paymentpanel"
            type="button"
            role="link"
            serviceclass="vehicle-item-1"
            errorMessage=""
            metaMessage=""
            location={true}
            onClick={() => {
              openPaymentModal();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                openPaymentModal();
              }
            }}
            canDetermineFinalCost={
              partnerDetails.experience.canDetermineFinalCost
            }
          >
            {isPepPartner || commonBenefits.benefits ? (
              <CustomerSupportIcon />
            ) : (
              <CreditCardIcon />
            )}
            <Icon
              icon="chevron"
              color="brand"
              size={1.2}
              className="icon-rotation-reverse vehicle-item-3"
            />
          </SelectablePanel>
        </div>
        <div className="l-grid__col">
          {isABGPartner && serviceInfo.id === TIRE_CHANGE_ID && (
            <SelectablePanel
              text={incidentReportText}
              name="radiooptions"
              ariaLabel="Complete Incident Report"
              value="incidentReport"
              type="button"
              role="link"
              serviceclass="vehicle-item-1"
              errorMessage=""
              metaMessage=""
              location={true}
              onClick={() => {
                handleIncidentReportClick();
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleIncidentReportClick();
                }
              }}
              canDetermineFinalCost={
                partnerDetails.experience.canDetermineFinalCost
              }
            >
              <ReportIcon />
              <Icon
                icon="chevron"
                color="brand"
                size={1.2}
                className="icon-rotation-reverse vehicle-item-3"
              />
            </SelectablePanel>
          )}
          <br />
          <Button
            size="lg"
            hasUpgrade
            id="submit-request"
            disabled={!formComplete}
            onClick={() => handleSubmit()}
            utils={{
              fullWidth: true,
            }}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.SUBMIT_REQUEST)}
          </Button>
        </div>
      </div>
    </>
  );
};
